import { Menu, X } from 'lucide-react';
import React, { useState } from 'react'
import ContactBtn from '../components/ContactBtn';

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const links = [
        { href: "#service", label: "Meine Services", },
        { href: "#about", label: "Über mich", },
        { href: "#reviews", label: "Rezensionen", },
    ];
    return (
        <nav className="bg-base-200 p-4">
            <div className="container mx-auto flex items-center justify-between">
                <div className="font-extrabold text-lg">Jonas Kaatz</div>
                {/* Burger Menu Button (hidden on larger screens) */}
                <div className="block md:hidden">
                    <button id="menu-btn" className=" focus:outline-none hover:text-[#958620]" onClick={() => setIsOpen(true)} ><Menu/></button>
                </div>
                {/* Links for larger screens */}
                <div className="hidden md:flex items-center space-x-6">
                    {links.map((link) => ( <a href={link.href} key={link.href} onClick={()=>setIsOpen(false)}  className="link link-hover" title={link.label} >{link.label}</a> ))}
                    <ContactBtn onClick={()=>setIsOpen(false)} />
                </div>
                <div id="overlay-menu" className={(isOpen?"":"hidden")+" fixed inset-0 bg-neutral-200 flex flex-col items-center justify-center space-y-8 z-50"}>
                    {links.map((link) => ( <a href={link.href} key={link.href} onClick={()=>setIsOpen(false)}  className="link link-hover" title={link.label} >{link.label}</a> ))}
                    {/* Close button always visible when overlay is active */}
                    <button id="close-btn" className="absolute top-4 right-4 focus:outline-none hover:text-[#958620]" onClick={() => setIsOpen(false)} ><X /></button>
                    <ContactBtn onClick={()=>setIsOpen(false)} />
                </div>

            </div>
        </nav>
    )
}
