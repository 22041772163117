import React, { useEffect, useState } from 'react'
import Service from './sections/Service';
import About from './sections/About';
import Reviews from './sections/Reviews';
import Navbar from './sections/Navbar';
import Hero from './sections/Hero';
import Footer from './sections/Footer';
import Contact from './sections/Contact';
import { useLocation } from 'react-router-dom';
import { ChevronUp } from 'lucide-react';
// import Prices from './sections/Prices';

function App() {
    const [ btnVisible, setBtnVisible ] = useState(false)
    const location = useLocation(); // Ensure this is correctly importe
    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) { element.scrollIntoView({ behavior: "smooth" }); }
        }
    }, [location]);

    const scroll2Top = () => { window.scrollTo(0,0); }
    useEffect(() => {
        // Button is displayed after scrolling for 300 pixels
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) { setBtnVisible(true); }
            else { setBtnVisible(false); }
        };
        window.addEventListener("scroll", toggleVisibility);
        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (

            <div className="flex flex-col">
                <Navbar />
                <div className="flex flex-col gap-10 lg:gap-20">
                    <Hero />
                    <Service />
                    <About />
                    {/* <Prices /> */}
                    <Reviews />
                    <Contact />
                    <Footer />
                </div>
                {btnVisible&&<button onClick={scroll2Top} className='fixed bottom-5 right-5 py-2 px-4 bg-indigo-500 hover:bg-indigo-600 text-white rounded-md text-center'><ChevronUp /></button>}
            </div>
    );
}

export default App;
