import React from 'react'
import ContactBtn from '../components/ContactBtn'

export default function About() {
    return (
        <section id="about" className='flex flex-col items-center m-1'>
            <h1 className='text-5xl font-bold'>Über mich</h1>
            <div className='bg-white rounded-2xl flex flex-col items-center md:flex-row gap-2 p-2 md:p-5 w-full max-w-5xl mt-7 shadow'>
                <div className='w-full md:w-1/3 flex justify-center'>
                    <img className='rounded-full max-w-96 w-fulls md: w-3/4 max-h-fit' src={require("../img/jonas.jpg")} alt="Logo"/>
                </div>
                <div className='w-full md:w-2/3 p-2'>
                    <h2 className='text-2xl font-bold mt-5 mb-2'>👨‍💻 Dein Partner für digitale Lösungen</h2>
                    <p className='mb-3 text-xls'>Hallo, mein Name ist Jonas! Ich bin 29 Jahre alt, komme aus dem Rheingau und bin leidenschaftlicher Softwareentwickler. Mein Ziel? Hochwertige digitale Lösungen, die nicht nur technisch überzeugen, sondern auch echte Mehrwerte für meine Kunden schaffen. Mit jahrelanger Erfahrung und einem Blick fürs Detail stehe ich dir als verlässlicher Partner zur Seite.</p>
                    <p className='mb-3 text-xls'>Neben meiner Arbeit begeistert mich vor allem das Reisen. Neue Orte zu entdecken, Kulturen zu erleben und Inspirationen zu sammeln, ist für mich essenziell. Eine weitere Leidenschaft von mir ist Kaffee - von der perfekten Zubereitung bis hin zur Suche nach der besten Rösterei.</p>
                    <h2 className='text-2xl font-bold mt-5 mb-2'>🚀 Warum mit mir zusammenarbeiten?</h2>
                    <p className='mb-3 text-xls'><span className='font-bold'>✅ Individuelle Lösungen</span> – Maßgeschneidert für dein Business.</p>
                    <p className='mb-3 text-xls'><span className='font-bold'>✅ Qualität & Transparenz</span> – Klare Kommunikation & erstklassige Ergebnisse.</p>
                    <p className='mb-3 text-xls'><span className='font-bold'>✅ Zuverlässigkeit</span> – Dein Projekt ist bei mir in besten Händen.</p>
                    <p className='mb-3 text-xls'>
                        <span className='font-bold'>📩 Lass uns sprechen!<br/></span>
                        Kontaktiere mich für ein unverbindliches Erstgespräch – ich freue mich darauf, deine Ideen in die Realität umzusetzen.
                    </p>
                    <div className='mt-4 flex justify-center'><ContactBtn text={'Schreib mir'}/></div>
                </div>
            </div>
        </section>
    )
}
