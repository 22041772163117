import React,{ useEffect,useState } from 'react'

export default function Reviews() {
    const [currentIndex,setCurrentIndex]=useState(0);
    const items=[
        {
            client:"Weingut Ferdinand Herke & Sohn",
            content:"Super schnelle und professionelle Abwicklung. Auch bei Problemen jederzeit ansprechbar.",
        },
        {
            client:"Florian Wunderle, Fa. FloTec",
            content:"Herr Kaatz hat mich mit seiner sehr selbständigen, kompetenten und lösungsorientierten Arbeitsweise absolut überzeugt. Die Integration von Bootstrap, in eine bestehendes Webprojekt wurde im Sommer 2023 innerhalb des vereinbarten Zeitrahmens perfekt umgesetzt. Ich freue mich auf weitere zukünftige Projekte mit Herrn Kaatz. ",
        },
        {
            client:"Daniel Alex-Laese, colpari GmbH",
            content:"Wir bei colpari waren sehr zufrieden mit der Arbeit von Jonas. Seine Kompetenzen in IT-Administration und Softwareentwicklung haben unsere Klienten und uns überzeugt. Er nimmt Herausforderungen gekonnt an, findet hervorragende Lösungen und kann technische Sachverhalte klar und präzise erklären Wir freuen uns auf die nächste Zusammenarbeit!",
        },
        {
            client:"Maren Altpeter, Made im Rheingau",
            content:"Sehr sympathische Zusammenarbeit und ein Dienstleister, der mitdenkt und auch eigene Ideen einbringt. Kein 0/8/15, sondern ein effizienter Austausch. Websiteaufbau, Strukturierung Webshop und sämtliche Follow-ups aus einer Hand und kompetent betreut.",
        },
        {
            client:"Regina Mohrbacher, Rechtsanwältin",
            content:"Seit Jahren unterstützt mich Herr Kaatz in meiner Anwaltskanzlei bei allen anfallenden Problemen mit dem Computer, Drucker, Telefon pp. Kleinere und größere PC Probleme hat er zeitnah mit großer Kompetenz, Ruhe und Professionalität gelöst. Die freundliche und schnelle Erledigung mit seiner fachlichen Expertise führt dazu, dass ich bei anfallenden Computerproblemen endlich Ruhe bewahren kann, denn ich weiß, Herr Kaatz wird sie wie immer zeitnah und schnell beheben.",
        },
        {
            client:"Kristina Arndt & Vanessa Bernstein, Weitblick GbR",
            content:"Herr Kaatz nimmt sich auch spontan Zeit für Ihr Unternehmen, wenn Sie Hilfe benötigen. Eine professionelle und schnelle Unterstützung."
        },
    ];
  
    const nextSlide=()=>{ setCurrentIndex((prevIndex)=>(prevIndex+1)%items.length); };
    const prevSlide=()=>{ setCurrentIndex((prevIndex)=>(prevIndex-1+items.length) % items.length); };

    useEffect(()=>{
        const changeInSec=5000;
        const interval=setInterval(nextSlide,changeInSec);
        return()=>clearInterval(interval);
    },[currentIndex]);
  
    return (
        <section id="reviews" className='flex flex-col items-center m-1'>
            <h1 className='text-5xl font-bold'>Rezensionen</h1>
            <div className='bg-white rounded-2xl flex flex-col smd:flex-row gap-2 p-2 md:p-5 w-full max-w-5xl mt-7 shadow'>
                <div className="relative w-full mx-auto overflow-hidden">
                    <button className="absolute left-0 z-10 top-1/2 stop-8 transform -translate-y-1/2 text-2xl text-white bg-gray-800  focus:outline-none p-3 bg-opacity-25 hover:bg-opacity-75 h-full rounded-lg" onClick={prevSlide} >‹</button>
                    <div className="flex transition-transform duration-500" style={{ transform: `translateX(-${currentIndex * 100}%)`, }} >
                    {items.map((item, index) => (
                            <div key={index} className="flex-none w-full text-center transform transition-all duration-500" >
                                <h2 className="text-2xl p-4 font-bold mx-6">{item.client}</h2>
                                <p className='p-3 mx-6'>{item.content}</p>
                            </div>
                        ))}
                    </div>
                    <button className="absolute right-0 top-1/2 stop-8 transform -translate-y-1/2 text-2xl text-white bg-gray-800  focus:outline-none p-3 bg-opacity-25 hover:bg-opacity-75 h-full rounded-lg" onClick={nextSlide} >›</button>
                </div>
            </div>
        </section>
    )
}
