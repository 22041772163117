import React from 'react'
import hero from "../img/hero1.jpg"
// import hero from "../img/hero2.jpg"
import ContactBtn from '../components/ContactBtn'

export default function Hero() {
    return (
        <div className="hero h-96" style={{ backgroundImage: 'url('+hero+')'}}>
            <div className="hero-overlay bg-opacity-80"></div>
            <div className="hero-content text-neutral-content text-center">
                <div className="max-w-2xl">
                    <h1 className="mb-5 text-3xl md:text-5xl font-bold"> Professionelles Webdesign & IT-Support für dein Business</h1>
                    <p className="mb-5">Optimierte Websites, zuverlässiger IT-Support und maßgeschneiderte digitale Lösungen – alles aus einer Hand. Lass uns dein Online-Erlebnis verbessern!</p>
                    <ContactBtn text={'Jetzt unverbindlich beraten lassen'} />
                </div>
            </div>
        </div>
    )
}
