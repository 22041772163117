import React from 'react'
import ContactBtn from '../components/ContactBtn'

const services = [
    {
        icon:"🛒",
        name:"Webshops",
        text:"Ich erstelle performante und optisch ansprechende Webshops, die für hohe Conversion-Raten optimiert sind. Ob WooCommerce, Shopify oder individuelle Lösungen - ich bringe dein Business online.",
    },
    {
        icon:"🌍",
        name:"Webseiten",
        text:"Professionelle Websites, die deine Marke optimal repräsentieren. Optimiert für Mobilgeräte, SEO-freundlich und auf maximale Ladegeschwindigkeit ausgelegt.",
    },
    {
        icon:"📊",
        name:"Beratung",
        text:"Ich unterstütze dich bei der Planung und Umsetzung deiner digitalen Projekte - von der Strategie bis zur technischen Umsetzung.",
    },
]

export default function Service() {
    return (
        <section id="service" className='flex flex-col items-center m-1'>
            <h1 className='text-5xl font-bold'>Meine Services</h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-5xl mt-7">
                {services.map((service:any) => {
                    return <div className="p-6 rounded-lg shadow text-center bg-white">
                        <span className="text-4xl">{service.icon}</span>
                        <h3 className="mt-4 text-xl font-semibold">{service.name}</h3>
                        <p className="mt-2">{service.text}</p>
                    </div>
                })}
            </div>


            {/* <div className='bg-white rounded-2xl flex flex-col smd:flex-row gap-2 p-2 md:p-5 w-full max-w-5xl mt-7'>
                <div className="w-full flex flex-col mb-5 items-start">
                    <h2 className='w-full font-bold text-xl'>🛒 Webshops - Maßgeschneiderte Online-Shops für mehr Umsatz</h2>
                    <p className='w-full'>Ich erstelle performante und optisch ansprechende Webshops, die für hohe Conversion-Raten optimiert sind. Ob WooCommerce, Shopify oder individuelle Lösungen - ich bringe dein Business online.</p>
                </div>
                <div className="w-full flex flex-col mb-5 items-start">
                    <h2 className='w-full font-bold text-xl'>🌍 Webseiten - Modern, schnell & nutzerfreundlich</h2>
                    <p className='w-full'>Professionelle Websites, die deine Marke optimal repräsentieren. Optimiert für Mobilgeräte, SEO-freundlich und auf maximale Ladegeschwindigkeit ausgelegt.</p>
                </div>
                <div className="w-full flex flex-col mb-5 items-start">
                    <h2 className='w-full font-bold text-xl'>💻 Individuelle Programmierung - Software nach Maß</h2>
                    <p className='w-full'>Benötigst du eine spezielle Webanwendung oder individuelle Funktionen? Ich entwickle maßgeschneiderte Softwarelösungen, die genau auf deine Bedürfnisse zugeschnitten sind.</p>
                </div>
                <div className="w-full flex flex-col mb-5 items-start">
                    <h2 className='w-full font-bold text-xl'>🛠️ IT-Support - Schnelle Hilfe bei technischen Problemen</h2>
                    <p className='w-full'>Ob Serverprobleme, Software-Fehlfunktionen oder Netzwerkeinrichtung - ich helfe dir schnell und zuverlässig.</p>
                </div>
                <div className="w-full flex flex-col mb-5 items-start">
                    <h2 className='w-full font-bold text-xl'>📊  Beratung - Digital erfolgreich mit Expertenwissen</h2>
                    <p className='w-full'>Ich unterstütze dich bei der Planung und Umsetzung deiner digitalen Projekte - von der Strategie bis zur technischen Umsetzung.</p>
                </div>
            </div> */}

            <div className='flex justify-center mt-4'>
                <ContactBtn />
            </div>
        </section>
    )
}
